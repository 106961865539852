import React, { useState } from "react";
import { ethers } from "ethers";

const TractorExecutor = ({ beanstalkContract }) => {
  const [requisition, setRequisition] = useState({
    blueprint: {
      publisher: "",
      data: "",
      operatorPasteInstrs: [],
      maxNonce: "",
      startTime: "",
      endTime: "",
    },
    blueprintHash: "",
    signature: "",
  });
  const [operatorData, setOperatorData] = useState("");
  const [executionResult, setExecutionResult] = useState(null);

  const handleRequisitionChange = (field, value) => {
    setRequisition((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleBlueprintChange = (field, value) => {
    setRequisition((prev) => ({
      ...prev,
      blueprint: {
        ...prev.blueprint,
        [field]: value,
      },
    }));
  };

  const executeTractorCall = async () => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      // Create a deep copy of the requisition to avoid mutating state
      const requisitionCopy = JSON.parse(JSON.stringify(requisition));

      // Only transform if it's a string, otherwise keep the array as is
      if (
        typeof requisitionCopy.blueprint.operatorPasteInstrs === "string" &&
        requisitionCopy.blueprint.operatorPasteInstrs.length > 0
      ) {
        requisitionCopy.blueprint.operatorPasteInstrs =
          requisitionCopy.blueprint.operatorPasteInstrs.split(",").map((str) => str.trim());
      }

      const beanstalk = beanstalkContract.connect(signer);
      const tx = await beanstalk.tractor(requisitionCopy, operatorData);
      const receipt = await tx.wait();

      setExecutionResult({
        success: true,
        transactionHash: receipt.transactionHash,
      });
    } catch (error) {
      console.error("Transaction failed:", error);
      console.error("Error details:", error.message);
      if (error.data) {
        console.error("Error data:", error.data);
      }
      setExecutionResult({
        success: false,
        error: error.message,
      });
    }
  };

  return (
    <div className="tractor-executor bg-gray-800 p-4 rounded-md mb-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-green-400">Execute Tractor Call</h2>
        <div className="relative">
          <input
            type="file"
            accept="application/json"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                  try {
                    const json = JSON.parse(event.target?.result);
                    handleBlueprintChange("publisher", json.publisher);
                    handleBlueprintChange("data", json.data);
                    if (json.operatorPasteInstr) {
                      handleBlueprintChange("operatorPasteInstrs", json.operatorPasteInstr);
                    } else if (json.operatorPasteInstrs) {
                      handleBlueprintChange("operatorPasteInstrs", json.operatorPasteInstrs);
                    }
                    handleBlueprintChange("maxNonce", json.maxNonce);
                    handleBlueprintChange("startTime", json.startTime);
                    handleBlueprintChange("endTime", json.endTime);
                    handleRequisitionChange("blueprintHash", json.blueprintHash);
                    handleRequisitionChange("signature", json.signature);
                  } catch (error) {
                    console.error("Error parsing JSON:", error);
                  }
                };
                reader.readAsText(file);
              }
            }}
            className="hidden"
            id="json-upload"
          />
          <label
            htmlFor="json-upload"
            className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded cursor-pointer"
          >
            Import JSON
          </label>
        </div>
      </div>

      <h3 className="text-lg font-semibold text-green-400 mt-4">Requisition</h3>
      <div className="mb-2">
        <input
          type="text"
          placeholder="Publisher"
          value={requisition.blueprint.publisher}
          onChange={(e) => handleBlueprintChange("publisher", e.target.value)}
          className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
        />
        <input
          type="text"
          placeholder="Data"
          value={requisition.blueprint.data}
          onChange={(e) => handleBlueprintChange("data", e.target.value)}
          className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
        />
        <input
          type="text"
          placeholder="Operator Paste Instructions (comma-separated)"
          value={requisition.blueprint.operatorPasteInstrs}
          onChange={(e) => handleBlueprintChange("operatorPasteInstrs", e.target.value)}
          className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
        />
        <div className="flex gap-2 mb-2">
          <input
            type="text"
            placeholder="Max Nonce"
            value={requisition.blueprint.maxNonce}
            onChange={(e) => handleBlueprintChange("maxNonce", e.target.value)}
            className="flex-1 p-2 bg-gray-700 text-white rounded"
          />
          <input
            type="text"
            placeholder="Start Time"
            value={requisition.blueprint.startTime}
            onChange={(e) => handleBlueprintChange("startTime", e.target.value)}
            className="flex-1 p-2 bg-gray-700 text-white rounded"
          />
          <input
            type="text"
            placeholder="End Time"
            value={requisition.blueprint.endTime}
            onChange={(e) => handleBlueprintChange("endTime", e.target.value)}
            className="flex-1 p-2 bg-gray-700 text-white rounded"
          />
        </div>
      </div>

      <input
        type="text"
        placeholder="Blueprint Hash"
        value={requisition.blueprintHash}
        onChange={(e) => handleRequisitionChange("blueprintHash", e.target.value)}
        className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
      />
      <input
        type="text"
        placeholder="Signature"
        value={requisition.signature}
        onChange={(e) => handleRequisitionChange("signature", e.target.value)}
        className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
      />

      <h3 className="text-lg font-semibold text-green-400 mt-4">Operator Data</h3>
      <input
        type="text"
        placeholder="Operator Data"
        value={operatorData}
        onChange={(e) => setOperatorData(e.target.value)}
        className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
      />

      <button
        onClick={executeTractorCall}
        className="bg-blue-500 hover:bg-blue-600 p-2 rounded-md text-white w-full mt-4"
      >
        Execute Tractor Call
      </button>

      {executionResult && (
        <div className="mt-4">
          {executionResult.success ? (
            <p className="text-green-400">
              Transaction successful! Hash: {executionResult.transactionHash}
            </p>
          ) : (
            <p className="text-red-400">Transaction failed: {executionResult.error}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TractorExecutor;
